import React from "react";
import Layout from "../components/layout";
import doulasStyles from "./doulas.module.css";
import {Container, Row, Col} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import educatorsList from "../config/educators";
import ContactUs from '../components/contact-us';
import MeetProfessionals from '../components/meet-professionals';


const EducatorsPage = () => {
  return (
    <Layout className="site-content">
      <Container fluid className={doulasStyles.containerStyle}>
        <Row className="workshop">
          <Col className={doulasStyles.largeTitle}>
            <h1 className={globalStyle.entryTitle}>MEET THE <span>EDUCATORS</span></h1>
          </Col>
        </Row>

        <MeetProfessionals professionalsList={educatorsList}></MeetProfessionals>
        <ContactUs></ContactUs>

      </Container>
    </Layout>
  )
};

export default EducatorsPage
