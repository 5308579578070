module.exports = [
  {
    "name": "Sierra Charter School",
    "website": "https://www.sierracharter.org/",
    "email": "info@sierracharter.org",
    "city": "Fresno",
    "phone": "559-490-4290",
    "state": "California"
  },
  {
    "name": "Kids Kare West",
    "website": "https://www.kidskarewest.net/home.php",
    "email": "karewest@kidskare.net",
    "city": "Fresno",
    "phone": "559-438-1921",
    "state": "California"
  },
  {
    "name": "Carden School",
    "website": "https://www.cardenschooloffresno.com/",
    "email": "office@cardenschooloffresno.com",
    "city": "Fresno",
    "phone": "559-323-0126",
    "state": "California"
  },
  {
    "name": "Kids Kare River Park",
    "website": "https://www.kidskareriverpark.com/home.php",
    "email": "riverpark@kidskare.net",
    "city": "Fresno",
    "phone": "559-431-2566",
    "state": "California"
  },
  {
    "name": "Fresno EOC School of Unlimited Learning",
    "website": "https://fresnoeoc.org/soul/#about",
    "email": "pr@fresnoeoc.org",
    "city": "Fresno",
    "phone": "559-498-8543",
    "state": "California"
  },
  {
    "name": "W. E. B. DuBois Public Charter School",
    "website": "https://www.agapeschools.org/web-dubois-charter-school.html",
    "email": "lscott@agapeschools.org",
    "city": "Fresno",
    "phone": "559-486-1166",
    "state": "California"
  },
  {
    "name": "Fresno Montessori School",
    "website": "https://www.thefresnomontessori.com/",
    "email": "fresnomontessori@gmail.com",
    "city": "Fresno",
    "phone": "559-432-3669",
    "state": "California"
  },
  {
    "name": "Fresno Christian Schools",
    "website": "https://www.fresnochristian.com/",
    "email": "info@fresnochristian.com",
    "city": "Fresno",
    "phone": "559-299-1695",
    "state": "California"
  },
  {
    "name": "Fresno Adventist Academ",
    "website": "http://www.faa.org/",
    "email": "office@faa.org",
    "city": "Fresno",
    "phone": "559-251-5548",
    "state": "California"
  },
  {
    "name": "Inspire Charter Central Office & Lending Library",
    "website": "https://inspireschools.org/",
    "email": "cherylm@inspireschools.org",
    "city": "Fresno",
    "phone": "559-754-1442",
    "state": "California"
  },
  {
    "name": "Central Learning Adult/Alternative School Site - CLASS",
    "website": "https://cl.centralunified.org/",
    "email": "class-group@centralusd.k12.ca.us",
    "city": "Fresno",
    "phone": "559-276-5230",
    "state": "California"
  },
  {
    "name": "Kids Kare Schools",
    "website": "https://www.kidskare.net/home.php",
    "email": "info@kidskare.net",
    "city": "Fresno",
    "phone": "559-275-1169",
    "state": "California"
  },
  {
    "name": "Fresno's West Park Charter Academy",
    "website": "https://www.westpark.k12.ca.us/",
    "email": "randy_r@wpesd.org",
    "city": "Fresno",
    "phone": "559-485-0727",
    "state": "California"
  },
  {
    "name": "NLDS",
    "website": "https://nlds.net/",
    "email": "1986NLDS@GMAIL.COM",
    "city": "Fresno",
    "phone": "559-221-8687",
    "state": "California"
  },
  {
    "name": "Clovis Christian Schools",
    "website": "https://www.clovischristianschools.com/",
    "email": "clovischristianpreschool@gmail.com",
    "city": "Fresno",
    "phone": "559-291-6302",
    "state": "California"
  },
  {
    "name": "Fresno Special Education",
    "website": "https://sped.fresnounified.org/",
    "email": "Angela.Dryden@fresnounified.org",
    "city": "Fresno",
    "phone": "559-457-3220",
    "state": "California"
  },
  {
    "name": "Nursing Assistant Training Academy Fresno",
    "website": "https://www.cnacademy.com/",
    "email": "NAACADEMY@YAHOO.COM",
    "city": "Fresno",
    "phone": "559-277-3369",
    "state": "California"
  },
  {
    "name": "Big Picture High School",
    "website": "https://www.bpeducationalacademy.org/",
    "email": "info@bigpicturelearning.org",
    "city": "Fresno",
    "phone": "559-420-1234",
    "state": "California"
  },
  {
    "name": "Kids Kare Ashlan",
    "website": "https://www.kidskareashlan.com/contactus.php?n=1&id=43",
    "email": "ashlan@kidskare.net",
    "city": "Fresno",
    "phone": "559-224-8656",
    "state": "California"
  },
  {
    "name": "Fresno Christian Early Education",
    "website": "https://www.fresnochristianearlyed.com/",
    "email": "fceeinfo@fresnochristian.com",
    "city": "Fresno",
    "phone": "559-298-3869",
    "state": "California"
  },
  {
    "name": "Aspen Valley Prep Academy Charter School",
    "website": "https://valley.aspenps.org/",
    "email": "jackson.roach@aspenps.org",
    "city": "Fresno",
    "phone": "559-225-7737",
    "state": "California"
  },
  {
    "name": "Kids Kare Fig Garden",
    "website": "https://www.kidskarefiggarden.com/home.php",
    "email": "figgarden@kidskare.net",
    "city": "Fresno",
    "phone": "559-436-0578",
    "state": "California"
  },
  {
    "name": "Koinonia Academic Center",
    "website": "https://www.cde.ca.gov/SchoolDirectory/details?cdscode=10621666156707",
    "email": "kacfresno@gmail.com",
    "city": "Fresno",
    "phone": "559-960-5153",
    "state": "California"
  },
  {
    "name": "Academy of Creative Education",
    "website": "https://www.academyofcreativeed.com/",
    "email": "ACELearning@yahoo.com",
    "city": "Fresno",
    "phone": "559-633-6615",
    "state": "California"
  },
  {
    "name": "New Life Discovery School",
    "website": "https://nlds.net/",
    "email": "1986NLDS@GMAIL.COM",
    "city": "Fresno",
    "phone": "559-226-8687",
    "state": "California"
  },
  {
    "name": "Kids Kare Olive Avenue",
    "website": "https://www.kidskareoliveavenue.com/home.php",
    "email": "oliveavenue@kidskare.net",
    "city": "Fresno",
    "phone": "559-485-6271",
    "state": "California"
  },
  {
    "name": "Herndon-Barstow Elementary School",
    "website": "https://hb.centralunified.org/",
    "email": "herndonbarstow-group@centralusd.k12.ca.us",
    "city": "Fresno",
    "phone": "559-276-5250",
    "state": "California"
  },
  {
    "name": "Educare Service Inc",
    "website": "http://www.educareservicesinc.com/",
    "email": "info@educareservicesinc.com",
    "city": "Fresno",
    "phone": "559-228-3232",
    "state": "California"
  },
  {
    "name": "Fresno High School",
    "website": "https://www.fresnou.org/schools/fresno",
    "email": "titleix@fresnounified.org",
    "city": "Fresno",
    "phone": "559-457-2780",
    "state": "California"
  },
  {
    "name": "Harvest Elementary School",
    "website": "https://ha.centralunified.org/",
    "email": "harvest-group@centralusd.k12.ca.us",
    "city": "Fresno",
    "phone": "559-271-0420",
    "state": "California"
  },
  {
    "name": "Fresno Unified Sch District Pace",
    "website": "https://www.fresnounified.org/",
    "email": "communications@fresnounified.org",
    "city": "Fresno",
    "phone": "559-248-7359",
    "state": "California"
  },
  {
    "name": "Pathway Community Day School",
    "website": "https://pcds.centralunified.org/",
    "email": "pathway-group@centralusd.k12.ca.us",
    "city": "Fresno",
    "phone": "559-487-1201",
    "state": "California"
  },
  {
    "name": "Central Unified School District",
    "website": "https://www.centralunified.org/",
    "email": "info@centralusd.k12.ca.us",
    "city": "Fresno",
    "phone": "559-274-4700",
    "state": "California",
  },
  {
    "name": "McKinley Elementary School",
    "website": "https://mk.centralunified.org/",
    "email": "mckinley-group@centralusd.k12.ca.us",
    "city": "Fresno",
    "phone": "559-276-5232",
    "state": "California"
  },
  {
    "name": "Bullard High School",
    "website": "https://www.fresnou.org/schools/bullard",
    "email": "communications@fresnounified.org",
    "city": "Fresno",
    "phone": "559-451-4320",
    "state": "California"
  },
  {
    "name": "John Steinbeck Elementary School",
    "website": "https://st.centralunified.org/",
    "email": "steinbeck-group@centralusd.k12.ca.us",
    "city": "Fresno",
    "phone": "559-276-3141",
    "state": "California"
  },
  {
    "name": "Shining Star Pre-School",
    "website": "https://www.shiningstarpreschool.org/",
    "email": "info@shiningstarpreschool.org",
    "city": "Fresno",
    "phone": "559-435-0349",
    "state": "California"
  },
  {
    "name": "Sunnyside High School",
    "website": "https://www.fresnou.org/schools/sunnyside",
    "email": "titleix@fresnounified.org",
    "city": "Fresno",
    "phone": "559-253-6700",
    "state": "California"
  },
  {
    "name": "Mayfair Elementary School",
    "website": "https://www.fresnou.org/schools/mayfair",
    "email": "communications@fresnounified.org",
    "city": "Fresno",
    "phone": "559-457-3140",
    "state": "California"
  },
  {
    "name": "Growing Patch Preschool",
    "website": "http://thegrowingpatch.com/",
    "email": "thegrowingpatchprivatepreschool@yahoo.com",
    "city": "Fresno",
    "phone": "559-435-4934",
    "state": "California"
  },
  {
    "name": "Parent Support Center For Child Development- Garfield Child Development Center at Glendale Community College",
    "website": "https://www.glendale.edu/academics/continuing-education-garfield/services/parent-support-center",
    "email": "tismail@glendale.edu",
    "city": "Glendale",
    "phone": "818-240-1000",
    "state": "California"
  },
  {
    "name": "The Realm Creative Academy",
    "website": "https://www.realmacademy.com/",
    "email": "info@realmacademy.com",
    "city": "Los Angeles",
    "phone": "310-399-1139",
    "state": "California"
  },
  {
    "name": "City of Angels School",
    "website": "https://www.stratfordschools.com/schools/southern-california/los-angeles/los-angeles/",
    "email": "melrose@stratfordschools.com",
    "city": "Los Angeles",
    "phone": "323-962-3075",
    "state": "California"
  },
  {
    "name": "Kids' World School",
    "website": "https://www.kidsworldschool.org/",
    "email": "info@kidsworldschool.org",
    "city": "Los Angeles",
    "phone": "323-663-4703",
    "state": "California"
  },
  {
    "name": "Angeles Workshop School",
    "website": "https://angelesworkshop.com/",
    "email": "angelesworkshop@gmail.com",
    "city": "Los Angeles",
    "phone": "562-441-8802",
    "state": "California"
  },
  {
    "name": "West Hollywood Children's Academy",
    "website": "https://www.whca91.com/",
    "email": "whca@whca91.com",
    "city": "Los Angeles",
    "phone": "310-748-1656",
    "state": "California"
  },
  {
    "name": "City Kids School",
    "website": "lacitykids.com",
    "email": "admissions@lacitykids.com",
    "city": "Los Angeles",
    "phone": "323-447-5437",
    "state": "California"
  },
  {
    "name": "Oak Crest Academy for Gifted Education",
    "website": "https://oakcrestacademy.org/",
    "email": "info@oakcrestacademy.org",
    "city": "Los Angeles",
    "phone": "866-782-8229",
    "state": "California"
  },
  {
    "name": "Citizens-World Charter School",
    "website": "http://www.citizensoftheworld.org/",
    "email": "marissa@citizensoftheworld.org",
    "city": "Los Angeles",
    "phone": "323-634-7100",
    "state": "California"
  },
  {
    "name": "Hollywood Schoolhouse",
    "website": "http://www.hshla.org/",
    "email": "tina@hshla.org",
    "city": "Los Angeles",
    "phone": "323-465-1320",
    "state": "California"
  },
  {
    "name": "Curtis School",
    "website": "https://www.curtisschool.org/",
    "email": "info@curtisschool.org",
    "city": "Los Angeles",
    "phone": "310-476-1251",
    "state": "California"
  },
  {
    "name": "After-School All-Stars",
    "website": "https://afterschoolallstars.org/",
    "email": "joe.mangione@afterschoolallstars.org",
    "city": "Los Angeles",
    "phone": "323-938-3232",
    "state": "California"
  },
  {
    "name": "Mills College: Children's School",
    "website": "https://www.mills.edu/",
    "email": "johnr@mills.edu",
    "city": "Oakland",
    "phone": "510-430-2118",
    "state": "California"
  },
  {
    "name": "Broadway Children's School",
    "website": "https://www.broadwaychildrensschool.org/",
    "email": "office@broadwaychildrensschool.org",
    "city": "Oakland",
    "phone": "510-763-9337",
    "state": "California"
  },
  {
    "name": "KSS Immersion Preschool of Oakland",
    "website": "https://ksspreschool.com/",
    "email": "inquiries@ksspreschool.com",
    "city": "Oakland",
    "phone": "877-442-2555",
    "state": "California"
  },
  {
    "name": "Escuela Bilingüe Internacional (EBI) | Preschool & 1st Grade School",
    "website": "https://www.ebinternacional.org/",
    "email": "pbaquerizo@ebinternacional.org",
    "city": "Oakland",
    "phone": "510-653-3324",
    "state": "California"
  },
  {
    "name": "Northern Light School",
    "website": "https://www.northernlightschool.com/",
    "email": "pwelch@northernlightschool.com",
    "city": "Oakland",
    "phone": "510-957-0570",
    "state": "California"
  },
  {
    "name": "Mountain Boulevard Montessori",
    "website": "http://mtnblvd.com/",
    "email": "mtnblvd@aol.com",
    "city": "Oakland",
    "phone": "510-482-2850",
    "state": "California"
  },
  {
    "name": "Park Day School",
    "website": "https://www.parkdayschool.org/",
    "email": "karen.colaric@parkdayschool.org",
    "city": "Oakland",
    "phone": "510-653-0317",
    "state": "California"
  },
  {
    "name": "Patten Academy of Christian",
    "website": "https://www.pattenacademy.org/",
    "email": "emarquez@pattenacademy.org",
    "city": "Oakland",
    "phone": "510-533-3121",
    "state": "California"
  },
  {
    "name": "Saint Vincent's Day Home",
    "website": "http://svdh.org/",
    "email": "info@svdh.org",
    "city": "Oakland",
    "phone": "510-832-8324",
    "state": "California"
  },
  {
    "name": "Urban Montessori Charter School",
    "website": "http://www.urbanmontessori.org/",
    "email": "info@urbanmontessori.org",
    "city": "Oakland",
    "phone": "510-842-1181",
    "state": "California"
  },
  {
    "name": "Redwood Day School",
    "website": "https://www.rdschool.org/",
    "email": "communications@rdschool.org",
    "city": "Oakland",
    "phone": "510-534-0800",
    "state": "California"
  },
  {
    "name": "Julia Morgan School for Girls",
    "website": "https://www.juliamorganschool.org/",
    "email": "mchin@juliamorganschool.org",
    "city": "Oakland",
    "phone": "510-632-6000",
    "state": "California"
  },
  {
    "name": "The Renaissance International School",
    "website": "https://www.therenaissanceschool.org/",
    "email": "info@therenaissanceschool.org",
    "city": "Oakland",
    "phone": "510-531-8566",
    "state": "California"
  },
  {
    "name": "Oakland Hebrew Day School",
    "website": "https://www.ohds.org/",
    "email": "info@ohds.org",
    "city": "Oakland",
    "phone": "510-531-8600",
    "state": "California"
  },
  {
    "name": "Head-Royce School",
    "website": "https://www.headroyce.org/summer",
    "email": "communications@headroyce.org",
    "city": "Oakland",
    "phone": "510-531-1300",
    "state": "California"
  },
  {
    "name": "Oakland Charter High School",
    "website": "https://ochs.amethodschools.org/",
    "email": "admin@amethodschools.org",
    "city": "Oakland",
    "phone": "510-436-0101",
    "state": "California"
  },
  {
    "name": "The Learning Forest Childcare Home and Preschool LLC",
    "website": "http://www.thelearningforest.com/",
    "email": "thelearningforestoakland@gmail.com",
    "city": "Oakland",
    "phone": "510-564-4633",
    "state": "California"
  },
  {
    "name": "Community School For Creative Education",
    "website": "https://communityschoolforcreativeeducation.org/",
    "email": "info@communityschoolforcreativeeducation.org",
    "city": "Oakland",
    "phone": "510-686-4131",
    "state": "California"
  },
  {
    "name": "Piedmont East Bay Children's Choir",
    "website": "https://www.piedmontchoirs.org/",
    "email": "inquiries@piedmontchoirs.org",
    "city": "Oakland",
    "phone": "510-547-4441",
    "state": "California"
  },
  {
    "name": "Kids Konnect Preschool",
    "website": "https://www.kids-konnect.com/",
    "email": "info@kids-konnect.com",
    "city": "Oakland",
    "phone": "510-746-0700",
    "state": "California"
  },
  {
    "name": "Lazear Charter Academy",
    "website": "https://www.efcps.org/",
    "email": "senrollment@efcps.net",
    "city": "Oakland",
    "phone": "510-689-2000",
    "state": "California"
  },
  {
    "name": "Kidango Castlemont Center",
    "website": "https://www.kidango.org/",
    "email": "hello@kidango.org",
    "city": "Oakland",
    "phone": "510-456-0876",
    "state": "California"
  },
  {
    "name": "Oakland Garden School",
    "website": "https://www.oaklandgardenschool.com/",
    "email": "director@oaklandgardenschool.com",
    "city": "Oakland",
    "phone": "510-531-4800",
    "state": "California"
  },
  {
    "name": "Betty's Children's Academy",
    "website": "https://www.bettyschildrensacademy.com/",
    "email": "bca1204@sbcglobal.net",
    "city": "Oakland",
    "phone": "510-430-8789",
    "state": "California"
  },
  {
    "name": "Oakland School For the Arts",
    "website": "https://www.oakarts.org/Contact/index.html",
    "email": "moz@oakarts.org",
    "city": "Oakland",
    "phone": "510-873-8800",
    "state": "California"
  },
  {
    "name": "Oakland Charter Academy",
    "website": "https://oca.amethodschools.org/",
    "email": "info@amethodschools.org",
    "city": "Oakland",
    "phone": "510-532-6751",
    "state": "California"
  },
  {
    "name": "Leadership Public Schools",
    "website": "https://www.leadps.org/",
    "email": "info@leadps.org",
    "city": "Oakland",
    "phone": "510-830-3780",
    "state": "California"
  },
  {
    "name": "Downtown Charter Academy",
    "website": "https://dca.amethodschools.org/",
    "email": "info@amethodschools.org",
    "city": "Oakland",
    "phone": "510-535-1580",
    "state": "California"
  },
  {
    "name": "Community Day School",
    "website": "https://www.ousd.org/communityday",
    "email": "gary.middleton@ousd.org ",
    "city": "Oakland",
    "phone": "510-531-6800",
    "state": "California"
  },
  {
    "name": "Learning Without Limits",
    "website": "https://www.efcps.org/",
    "email": "senrollment@efcps.net",
    "city": "Oakland",
    "phone": "510-879-1282",
    "state": "California"
  },
  {
    "name": "Rockridge Montessori School",
    "website": "https://www.rockridgemontessori.org/",
    "email": "admin@rockridgemontessori.org",
    "city": "Oakland",
    "phone": "510-652-7021",
    "state": "California"
  },
  {
    "name": "Envision Learning",
    "website": "https://envisionschools.org/",
    "email": "info@envisionschools.org",
    "city": "Oakland",
    "phone": "510-451-2415",
    "state": "California"
  },
  {
    "name": "St. Anthony School",
    "website": "https://stanthony-oakland.org/",
    "email": "stanthony@csdo.org",
    "city": "Oakland",
    "phone": "510-534-3334",
    "state": "California"
  },
  {
    "name": "ASCEND",
    "website": "https://www.efcps.org/",
    "email": "senrollment@efcps.net",
    "city": "Oakland",
    "phone": "510-879-3140",
    "state": "California"
  },
  {
    "name": "Mr. D's Music Club - Kids Music & Singing Classes ",
    "website": "https://www.mrdsmusicclub.com/",
    "email": "Info@mrdsmusicclub.com",
    "city": "Oakland",
    "phone": "510-329-7020",
    "state": "California"
  },
  {
    "name": "Casa de Mateo Child Care & Preschool",
    "website": "https://www.casademateo.org/contact",
    "email": "info@casademateo.org",
    "city": "Oakland",
    "phone": "510-246-6688",
    "state": "California"
  },
  {
    "name": "Achieve Academy",
    "website": "https://www.efcps.org/contact",
    "email": "aarciga@efcps.net",
    "city": "Oakland",
    "phone": "510-904-6440",
    "state": "California"
  },
  {
    "name": "Diocese of Oakland School Department",
    "website": "https://www.csdo.org/contact-us",
    "email": "sdept@csdo.org",
    "city": "Oakland",
    "phone": " 510-628-2154",
    "state": "California"
  },
  {
    "name": "City Oakland Head Start",
    "website": "https://www.oaklandca.gov/topics/head-start",
    "email": "digitalteam@oaklandca.gov",
    "city": "Oakland",
    "phone": "510-615-5586",
    "state": "California"
  },
  {
    "name": "Daisy Children's Development Center",
    "website": "http://www.daisycdc.com/",
    "email": "daisy94619@yahoo.com",
    "city": "Oakland",
    "phone": "510-531-6426",
    "state": "California"
  },
  {
    "name": "North Schools",
    "website": "https://inspireschools.org/",
    "email": "cherylm@inspireschools.org",
    "city": "Sacramento",
    "phone": "916-568-9959",
    "state": "California"
  },
  {
    "name": "Heritage Peak Charter School",
    "website": "https://pacificcharters.org/contact-us/",
    "email": "newenrollment@pacificcharters.org",
    "city": "Sacramento",
    "phone": "916-375-0401",
    "state": "California"
  },
  {
    "name": "Capital Innovations Academy",
    "website": "https://www.ourciacademy.com/",
    "email": "sbarbulesco@ourciacademy.com",
    "city": "Sacramento",
    "phone": "530-400-7619",
    "state": "California"
  },
  {
    "name": "Courtyard Private School",
    "website": "https://courtyard.org/",
    "email": "info@courtyard.org",
    "city": "Sacramento",
    "phone": "916-442-5395",
    "state": "California"
  },
  {
    "name": "Natomas Charter School",
    "website": "https://www.natomascharter.org/",
    "email": "jtorres@natomascharter.org",
    "city": "Sacramento",
    "phone": "916-928-5353",
    "state": "California"
  },
  {
    "name": "Capital City School",
    "website": "https://www.scusd.edu/school/capital-city-school",
    "email": "webhelp@scusd.edu",
    "city": "Sacramento",
    "phone": "916-433-5187",
    "state": "California"
  },
  {
    "name": "SacMakers Academy/ MakersXD",
    "website": "https://makersxd.com/",
    "email": "info@makersxd.com",
    "city": "Sacramento",
    "phone": "916-903-8013",
    "state": "California"
  },
  {
    "name": "Growing Brilliant Preschool Natomas",
    "website": "https://www.growingbrilliant.com/natomas-preschool/",
    "email": "info@growingbrilliant.com",
    "city": "Sacramento",
    "phone": "916-900-4222",
    "state": "California"
  },
  {
    "name": "Choices Charter School",
    "website": "https://choicescharter.org/",
    "email": "info@choicescharter.org",
    "city": "Sacramento",
    "phone": "916-979-8378",
    "state": "California"
  },
  {
    "name": "Camellia Waldorf School",
    "website": "https://www.camelliawaldorf.org/",
    "email": "frontdesk@camelliawaldorf.org",
    "city": "Sacramento",
    "phone": "916-427-5022",
    "state": "California"
  },
  {
    "name": "4th R School Age Child Care",
    "website": "https://www.cityofsacramento.org/ParksandRec/Contact",
    "email": "accessLeisure@cityofsacramento.org",
    "city": "Sacramento",
    "phone": "916-264-8385",
    "state": "California"
  },
  {
    "name": "Rio Valley Charter School",
    "website": "https://pacificcharters.org/",
    "email": "newenrollment@pacificcharters.org",
    "city": "Sacramento",
    "phone": "916-451-0401",
    "state": "California"
  },
  {
    "name": "Bergamo Montessori School",
    "website": "https://www.bergamoschools.com/",
    "email": "hello@bergamoSchools.com",
    "city": "Sacramento",
    "phone": "916-399-1900",
    "state": "California"
  },
  {
    "name": "Caring Connection Children's Center",
    "website": "http://caringconnectionchildrenscenter.com/",
    "email": "info@caringconnectionchildrenscenter.com",
    "city": "Sacramento",
    "phone": "916-261-0796",
    "state": "California"
  },
  {
    "name": "Hiram Johnson Family Education Center",
    "website": "https://www.scusd.edu/department-quick-link/hiram-johnson-family-education-center",
    "email": "webhelp@scusd.edu",
    "city": "Sacramento",
    "phone": "916-277-7047",
    "state": "California"
  },
  {
    "name": "Capital Christian School",
    "website": "https://capitalchristian.school/",
    "email": "info@capitalchristian.school",
    "city": "Sacramento",
    "phone": "916-856-5600",
    "state": "California"
  },
  {
    "name": "Sacramento Charter High School",
    "website": "https://www.sthope.org/sac-high-home",
    "email": "info@sthopepublicschools.org",
    "city": "Sacramento",
    "phone": "916-277-6200",
    "state": "California"
  },
  {
    "name": "Sacramento City Unified School",
    "website": "https://www.scusd.edu/",
    "email": "webhelp@scusd.edu",
    "city": "Sacramento",
    "phone": "916-228-5850",
    "state": "California"
  },
  {
    "name": "Mae's Home Daycare Wonderschool",
    "website": "https://www.wonderschool.com/",
    "email": "hello@wonderschool.com",
    "city": "Sacramento",
    "phone": "916-237-5902",
    "state": "California"
  },
  {
    "name": "Sacramento Montessori School",
    "website": "https://www.montessorisacramento.com/",
    "email": "admin@montessorisacramento.com",
    "city": "Sacramento",
    "phone": "916-426-7009",
    "state": "California"
  },
  {
    "name": "Keema High School",
    "website": "http://khs.twinriversusd.org/",
    "email": "20publiccomment@twinriversusd.org",
    "city": "Sacramento",
    "phone": "916-566-3410",
    "state": "California"
  },
  {
    "name": "Sacramento City Unified Sch",
    "website": "https://www.scusd.edu/",
    "email": "webhelp@scusd.edu",
    "city": "Sacramento",
    "phone": "916-277-6716",
    "state": "California"
  },
  {
    "name": "Sacred Heart Parish School",
    "website": "http://sacredheartschool.net/",
    "email": "lzickefoose@sacredheartschool.net",
    "city": "Sacramento",
    "phone": "916-456-1576",
    "state": "California"
  },
  {
    "name": "Presentation School",
    "website": "http://www.presentationschool.net/Home.aspx?Community=95821001",
    "email": "info@presentationschool.net",
    "city": "Sacramento",
    "phone": "916-482-0351",
    "state": "California"
  },
  {
    "name": "Montessori Country Day",
    "website": "https://www.bergamoschools.com//",
    "email": "hello@BergamoSchools.com",
    "city": "Sacramento",
    "phone": "916-427-1900",
    "state": "California"
  },
  {
    "name": "Creative Spaces Preschool",
    "website": "https://creativespaceslearning.com/",
    "email": "Director@CreativeSpacesLearning.com",
    "city": "Sacramento",
    "phone": "916-431-7235",
    "state": "California"
  },
  {
    "name": "Futures High School",
    "website": "https://www.fhscharter.org/",
    "email": "Jason.sample@gcccharters.org",
    "city": "Sacramento",
    "phone": "916-286-1902",
    "state": "California"
  },
  {
    "name": "Golden Valley Academy",
    "website": "https://www.goldenvalleyacademy.com/",
    "email": "staff@goldenvalleyacademy.com",
    "city": "Sacramento",
    "phone": "916-689-8823",
    "state": "California"
  },
  {
    "name": "St Charles Borromeo School",
    "website": "https://scbsac.org/",
    "email": "scboffice@scbsac.net",
    "city": "Sacramento",
    "phone": "916-421-6189",
    "state": "California"
  },
  {
    "name": "Collis P Huntington Preschool",
    "website": "https://www.playhousepreschoolsac.com/",
    "email": "of@seta.net",
    "city": "Sacramento",
    "phone": "916-433-5438",
    "state": "California"
  },
  {
    "name": "Inderkum High School",
    "website": "https://natomasunified.org/ihs/",
    "email": "customerservice@natomasunified.org",
    "city": "Sacramento",
    "phone": "916-567-5640",
    "state": "California"
  },
  {
    "name": "Natomas High School",
    "website": "https://natomasunified.org/nhs/",
    "email": "customerservice@natomasunified.org",
    "city": "Sacramento",
    "phone": "916-641-4960",
    "state": "California"
  },
  {
    "name": "Grant Union High School",
    "website": "http://ghs.twinriversusd.org/",
    "email": "geoffrey.kniesch@twinriversusd.org",
    "city": "Sacramento",
    "phone": "916-566-3450",
    "state": "California"
  },
  {
    "name": "Freeport SETA Head Start",
    "website": "https://headstart.seta.net/",
    "email": "of@seta.net",
    "city": "Sacramento",
    "phone": "916-433-2777",
    "state": "California"
  },
  {
    "name": "Ethel Phillips Elementary School",
    "website": "https://ethelphillips.scusd.edu/",
    "email": "info@scusd.edu",
    "city": "Sacramento",
    "phone": "916-277-6277",
    "state": "California"
  },
  {
    "name": "Twin Rivers Unified School",
    "website": "https://www.twinriversusd.org/",
    "email": "geoffrey.kniesch@twinriversusd.org",
    "city": "Sacramento",
    "phone": "916-649-6600",
    "state": "California"
  },
  {
    "name": "Jefferson School",
    "website": "https://natomasunified.org/jfs/",
    "email": "jfsvirtual@natomasunified.org",
    "city": "Sacramento",
    "phone": "916-567-5580",
    "state": "California"
  },
  {
    "name": "Matsuyama Elementary School",
    "website": "https://matsuyama.scusd.edu/",
    "email": "support@scusd.edu",
    "city": "Sacramento",
    "phone": "916-395-4650",
    "state": "California"
  },
  {
    "name": "Northwood Elementary School",
    "website": "http://northwood.twinriversusd.org/",
    "email": "geoffrey.kniesch@twinriversusd.org",
    "city": "Sacramento",
    "phone": "916-566-2705",
    "state": "California"
  },
  {
    "name": "Mindful Miracle",
    "website": "https://www.mindfulmiracle.com/",
    "email": "hello@mindfulmiracle.com",
    "city": "Sacramento",
    "phone": "916-586-8881",
    "state": "California"
  },
  {
    "name": "San Diego Neighborhood Homeschool",
    "website": "https://www.homeschool-life.com/772/",
    "email": "info@homeschool-life.com",
    "city": "San Diego",
    "phone": "619-422-5216",
    "state": "California"
  },
  {
    "name": "The Charter School Of San Diego",
    "website": "https://charterschool-sandiego.net/",
    "email": "jrobertson@charterschool-sandiego.net",
    "city": "San Diego",
    "phone": "858-678-2020",
    "state": "California"
  },
  {
    "name": "The Child's Primary School",
    "website": "https://tcps.org/",
    "email": "mcairns@tcps.org",
    "city": "San Diego",
    "phone": "858-576-2120",
    "state": "California"
  },
  {
    "name": "Innovations Academy",
    "website": "https://innovationsacademy.org/",
    "email": "info@innovationsacademy.org",
    "city": "San Diego",
    "phone": "858-271-1414",
    "state": "California"
  },
  {
    "name": "Gizmos & Gadgets Kids Lab",
    "website": "http://www.gizmogadgetlab.com/",
    "email": "info.gizmogadgetlab@gmail.com",
    "city": "San Diego",
    "phone": "619-306-6950",
    "state": "California"
  },
  {
    "name": "San Diego Virtual School",
    "website": "https://sdvirtualschools.com/locations/",
    "email": "bmclaughlin@sdvirtualschools.com",
    "city": "San Diego",
    "phone": "619-713-7273",
    "state": "California"
  },
  {
    "name": "Childrens Creative & Performing Arts Academy",
    "website": "https://www.ccpaasd.com/",
    "email": "info@ccpaasd.com",
    "city": "San Diego",
    "phone": "619-584-2454",
    "state": "California"
  },
  {
    "name": "Futures Academy - San Diego",
    "website": "https://www.futures.edu/",
    "email": "mmcmahon@futures.edu",
    "city": "San Diego",
    "phone": "866-814-5517",
    "state": "California"
  },
  {
    "name": "Audeo Charter School",
    "website": "https://www.audeocharterschool.net/",
    "email": "sstarr@audeocharterschool.net",
    "city": "San Diego",
    "phone": "858-203-4643",
    "state": "California"
  },
  {
    "name": "Maria Montessori School",
    "website": "https://montessorischoolhouse.org/",
    "email": "mariamontessorischools@gmail.com",
    "city": "San Diego",
    "phone": "858-270-9350",
    "state": "California"
  },
  {
    "name": "City Tree Christian School",
    "website": "https://www.citytree.org/",
    "email": "info@citytree.org",
    "city": "San Diego",
    "phone": "619-232-3794",
    "state": "California"
  },
  {
    "name": "The Waldorf School Of San Diego High School",
    "website": "https://waldorfsandiego.org/",
    "email": "admissions@waldorfsandiego.org",
    "city": "San Diego",
    "phone": "619-280-8016",
    "state": "California"
  },
  {
    "name": "ABC Children's Center at San Diego",
    "website": "http://abckidsrb.com/",
    "email": "office@abckidsrb.com",
    "city": "San Diego",
    "phone": "858-451-1663",
    "state": "California"
  },
  {
    "name": "Heritage Christian School",
    "website": "https://www.hcssd.org/",
    "email": "mail@hcssd.org",
    "city": "San Diego",
    "phone": "858-541-2254",
    "state": "California"
  },
  {
    "name": "Del Mar Pines School",
    "website": "https://delmarpines.com/",
    "email": "info@delmarpines.com",
    "city": "San Diego",
    "phone": "858-481-5615",
    "state": "California"
  },
  {
    "name": "Lifetime Montessori School",
    "website": "https://lifetimemontessorischool.com/",
    "email": "info@lifetimemontessorischool.com",
    "city": "San Diego",
    "phone": "858-759-0631",
    "state": "California"
  },
  {
    "name": "North Park Christian School",
    "website": "http://npc-school.com/index.html",
    "email": "school@npc-school.com",
    "city": "San Diego",
    "phone": "619-220-0372",
    "state": "California"
  },
  {
    "name": "Scripps Montessori School",
    "website": "https://www.scrippsmontessori.com/",
    "email": "info@scrippsmontessori.com",
    "city": "San Diego",
    "phone": "858-566-3632",
    "state": "California"
  },
  {
    "name": "Montessori School-Kearny Mesa",
    "website": "https://www.montessorischoolofoceanside.com/kearny-mesa/kearny-mesa-location/",
    "email": "msopto@yahoo.com",
    "city": "San Diego",
    "phone": "858-505-0332",
    "state": "California"
  },
  {
    "name": "Fusion Academy San Diego",
    "website": "https://www.fusionacademy.com/campuses/san-diego/",
    "email": "sandiego@fusionacademy.com",
    "city": "San Diego",
    "phone": "858-677-9279",
    "state": "California"
  },
  {
    "name": "Polinsky School",
    "website": "https://promises2kids.org/polinsky-center/",
    "email": "info@promises2kids.org",
    "city": "San Diego",
    "phone": "858-514-4710",
    "state": "California"
  },
  {
    "name": "Coastal Christian Academy and Preschool",
    "website": "https://coastalsd.org/preschool",
    "email": "heidi@coastalsd.org",
    "city": "San Diego",
    "phone": "858-598-6846",
    "state": "California"
  },
  {
    "name": "Casa Verde School",
    "website": "https://casaverdeschool.com/",
    "email": "info@casaverdeschool.com",
    "city": "San Diego",
    "phone": "619-847-1316",
    "state": "California"
  },
  {
    "name": "St. Paul's Child Care Program",
    "website": "https://www.stpaulseniors.org/services/childcare-program/",
    "email": "info@stpaulseniors.org",
    "city": "San Diego",
    "phone": "619-239-6900",
    "state": "California"
  },
  {
    "name": "Canyon Rim Children's Center Inc",
    "website": "http://www.baldwinacademy.com/",
    "email": "darla@baldwinacademy.com",
    "city": "San Diego",
    "phone": "858-270-5995",
    "state": "California"
  },
  {
    "name": "Snapology of San Diego",
    "website": "https://www.snapology.com/location/sandiego",
    "email": "sandiego@snapology.com",
    "city": "San Diego",
    "phone": "619-855-4557",
    "state": "California"
  },
  {
    "name": "The Vine Learning Center",
    "website": "https://www.thevinelearningcenter1.com/",
    "email": "thevine2007info@gmail.com",
    "city": "San Diego",
    "phone": "858-974-1222",
    "state": "California"
  },
  {
    "name": "Thrive Public Schools",
    "website": "https://www.thriveps.org/",
    "email": "contact@thriveps.org",
    "city": "San Diego",
    "phone": "619-839-5678",
    "state": "California"
  },
  {
    "name": "Notre Dame Academy",
    "website": "https://ndasd.org/",
    "email": "ndaadministration@ndasd.org",
    "city": "San Diego",
    "phone": "858-509-2300",
    "state": "California"
  },
  {
    "name": "Fleur De Lis School",
    "website": "https://www.fleurdelisschool.com/",
    "email": "info@fleurdelisschool.com",
    "city": "San Diego",
    "phone": "619-295-6781",
    "state": "California"
  },
  {
    "name": "Grow Smart Children's Academy",
    "website": "http://www.growsmartacademy.com/",
    "email": "growsmart2013@hotmail.com",
    "city": "San Diego",
    "phone": "619-464-0305",
    "state": "California"
  },
  {
    "name": "ABC Learning Center, Family Home ChildCare",
    "website": "http://www.abclearningsd.com/",
    "email": "AbcLearningsd@gmail.com",
    "city": "San Diego",
    "phone": "858-433-5393",
    "state": "California"
  },
  {
    "name": "Montessori School of San Diego",
    "website": "http://www.montessori-of-sandiego.com/",
    "email": "montessorisandiego@sbcglobal.net",
    "city": "San Diego",
    "phone": "619-295-7591",
    "state": "California"
  },
  {
    "name": "All Saints' Episcopal Preschool",
    "website": "https://sdallsaints.com/",
    "email": "battles@sdallsaints.com",
    "city": "San Diego",
    "phone": "619-298-1671",
    "state": "California"
  },
  {
    "name": "Sparrow Waldorf Home School",
    "website": "https://nuasparrow.org/contact/",
    "email": "smedina@nu.edu",
    "city": "San Diego",
    "phone": "619-462-1853",
    "state": "California"
  },
  {
    "name": "Audeo Charter School.",
    "website": "https://www.audeocharterschool.net/contact/",
    "email": " info@audeocharterschool.net",
    "city": "San Diego",
    "phone": "858-678-2050",
    "state": "California"
  },
  {
    "name": "Stratford School - San Francisco",
    "website": "https://www.stratfordschools.com/schools/northern-california/san-francisco/san-francisco-demontfort/",
    "email": "sf@stratfordschools.com",
    "city": "San Francisco",
    "phone": "415-333-3134",
    "state": "California"
  },
  {
    "name": "The Growing Tree School",
    "website": "https://www.thegrowingtreeschool.com/",
    "email": "info@thegrowingtreeschool.com",
    "city": "San Francisco",
    "phone": "415-668-2918",
    "state": "California"
  },
  {
    "name": "San Francisco Unified School",
    "website": "https://www.sfusd.edu/",
    "email": "familylink@sfusd.edu",
    "city": "San Francisco",
    "phone": "415-695-5691",
    "state": "California"
  },
  {
    "name": "Fusion Academy San Francisco",
    "website": "https://www.fusionacademy.com/campuses/san-francisco/?utm_source=GMB&utm_medium=organic&utm_campaign=campuses",
    "email": "sanfrancisco@fusionacademy.com",
    "city": "San Francisco",
    "phone": "415-765-9078",
    "state": "California"
  },
  {
    "name": "San Francisco Christian School",
    "website": "https://www.sfchristianschool.org/",
    "email": "office@sfchristianschool.org",
    "city": "San Francisco",
    "phone": "415-802-2851",
    "state": "California"
  },
  {
    "name": "Live Oak School",
    "website": "https://www.liveoaksf.org/",
    "email": "info@liveoaksf.org",
    "city": "San Francisco",
    "phone": "415-861-8840",
    "state": "California"
  },
  {
    "name": "La Scuola International School | Preschool Campus",
    "website": "https://www.lascuolasf.org/learning/preschool",
    "email": "dani.sollberger@lascuolasf.org",
    "city": "San Francisco",
    "phone": "415-558-9006",
    "state": "California"
  },
  {
    "name": "San Francisco University High School",
    "website": "https://www.sfuhs.org/",
    "email": "jenn.soult@sfuhs.org",
    "city": "San Francisco",
    "phone": "415-447-3100",
    "state": "California"
  },
  {
    "name": "AppleSeed Almaden Montessori School",
    "website": "https://www.challengerschool.com/campus/california/san-jose/berryessa?utm_source=gmb&utm_medium=organic&utm_term=ba",
    "email": "​​khendricks@appleseedalmaden.com",
    "city": "San Jose",
    "phone": "408-264-7333",
    "state": "California"
  },
  {
    "name": "WholeKids Montessori Bilingual Home School",
    "website": "http://wholekidsmontessori.com/",
    "email": "sonia.kuo@wholekidsmontessori.com",
    "city": "San Jose",
    "phone": "408-257-2084",
    "state": "California"
  },
  {
    "name": "Challenger School",
    "website": "https://www.challengerschool.com/campus/california/san-jose/berryessa?utm_source=gmb&utm_medium=organic&utm_term=baa",
    "email": "HR@challengerschool.com",
    "city": "San Jose",
    "phone": "408-998-2860",
    "state": "California"
  },
  {
    "name": "Learning Options Center/Liberty High/Home School/ ACE Adult Career Education",
    "website": "https://www.cde.ca.gov/SchoolDirectory/details?cdscode=43696664330502",
    "email": "cmolina@sjusd.org",
    "city": "San Jose",
    "phone": "408-535-6539",
    "state": "California"
  },
  {
    "name": "Almaden Country Day School",
    "website": "https://www.almadencountrydayschool.org/",
    "email": "info@almadencountryday.org",
    "city": "San Jose",
    "phone": "408-997-0424",
    "state": "California"
  },
  {
    "name": "Stratford School",
    "website": "https://www.stratfordschools.com/schools/northern-california/south-bay/san-jose/",
    "email": "sj@stratfordschools.com",
    "city": "San Jose",
    "phone": "408-363-2130",
    "state": "California"
  },
  {
    "name": "Discovery Charter School",
    "website": "https://www.discoveryk8.org/",
    "email": "sleftwich@discoveryk8.org",
    "city": "San Jose",
    "phone": "408-243-9800",
    "state": "California"
  },
  {
    "name": "Merryhill Elementary School",
    "website": "https://www.merryhillschool.com/elementary/san-jose/north-capitol-ave/?/utm_medium=directory",
    "email": "swong1@springedugroup.com",
    "city": "San Jose",
    "phone": "408-254-1282",
    "state": "California"
  },
  {
    "name": "Action Day Primary Plus",
    "website": "https://www.actiondayprimaryplus.com/locations/allen/",
    "email": "allenpreschool@actiondayprimaryplus.com",
    "city": "San Jose",
    "phone": "408-629-6020",
    "state": "California"
  },
  {
    "name": "Kidztopros at Farnham Charter School",
    "website": "https://www.kidztopros.com/",
    "email": "contact@kidztopros.com",
    "city": "San Jose",
    "phone": "510-356-8726",
    "state": "California"
  },
  {
    "name": "BASIS Independent Schools",
    "website": "https://siliconvalley.basisindependent.com/",
    "email": "siliconvalley-admissions@basisindependent.com",
    "city": "San Jose",
    "phone": "408-291-0907",
    "state": "California"
  },
  {
    "name": "Mandala Children's House",
    "website": "http://mandalachildrenshouse.com/",
    "email": "info@mandalachildrenshouse.com",
    "city": "San Jose",
    "phone": "408-251-8633",
    "state": "California"
  },
  {
    "name": "AppleSeed Montessori School",
    "website": "http://www.childrenshouseacademy.com/",
    "email": "info_deanza@appleseedmontessori.com",
    "city": "San Jose",
    "phone": "408-470-4701",
    "state": "California"
  },
  {
    "name": "Creekside School",
    "website": "https://www.creeksideschool.org/",
    "email": "info@creeksidechool.org",
    "city": "San Jose",
    "phone": "408-448-2494",
    "state": "California"
  },
  {
    "name": "Fuze Fit 2 Play",
    "website": "https://www.fuzefit.com/",
    "email": "info@fuzefit.com ",
    "city": "San Jose",
    "phone": "650-666-9029",
    "state": "California"
  },
  {
    "name": "Rocketship Public Schools",
    "website": "https://www.rocketshipschools.org/schools/discovery-prep/",
    "email": "discoveryprep@rsed.org",
    "city": "San Jose",
    "phone": "408-217-8951",
    "state": "California"
  },
  {
    "name": "Cristo Rey San José Jesuit High School",
    "website": "https://www.cristoreysanjose.org/",
    "email": "emily.cunningham@cristoreysanjose.org",
    "city": "San Jose",
    "phone": "408-293-0425",
    "state": "California"
  },
  {
    "name": "KIPP Public Schools ",
    "website": "https://kippnorcal.org/wp-signup.php?new=www.kippbayarea.org",
    "email": "info@kippnorcal.org",
    "city": "San Jose",
    "phone": "20-5010766",
    "state": "California"
  },
  {
    "name": "Discovery Charter Schools",
    "website": "https://www.discoveryk8.org/",
    "email": "sleftwich@discoveryk8.org",
    "city": "San Jose",
    "phone": "408-243-9800",
    "state": "California"
  },
  {
    "name": "One World Montessori School",
    "website": "https://oneworldmontessori.org/",
    "email": "office@oneworldmontessori.org",
    "city": "San Jose",
    "phone": "408-723-5140",
    "state": "California"
  },
  {
    "name": "Acton Academy",
    "website": "https://actonsanjose.org/",
    "email": "info@ActonSanJose.org",
    "city": "San Jose",
    "phone": "415-927-1388",
    "state": "California"
  },
  {
    "name": "Starbright School",
    "website": "https://www.starbright.com/",
    "email": "hello@starbright.com",
    "city": "San Jose",
    "phone": "408-985-1460",
    "state": "California"
  },
  {
    "name": "Explorer Preschool",
    "website": "https://www.explorerpreschool.org/",
    "email": "publicity@explorerpreschool.org",
    "city": "San Jose",
    "phone": "408-723-0757",
    "state": "California"
  },
  {
    "name": "Pasitos School",
    "website": "http://www.pasitosschool.com/",
    "email": "join@pasitosschool.com",
    "city": "San Jose",
    "phone": "408-244-3551",
    "state": "California"
  },
  {
    "name": "Almaden Parents' Pre-School",
    "website": "https://appsonline.org/",
    "email": "contact@appsonline.org",
    "city": "San Jose",
    "phone": "408-225-7211",
    "state": "California"
  },
  {
    "name": "Kinderwood Children's Center",
    "website": "https://kinderwoodchildrenscenter.com/",
    "email": "director@kinderwoodchildrenscenter.com",
    "city": "San Jose",
    "phone": "408-363-1366",
    "state": "California"
  },
  {
    "name": "Santana Care Montessori school",
    "website": "https://santanacare.com/",
    "email": "info@santanacare.com",
    "city": "San Jose",
    "phone": "408-247-2880",
    "state": "California"
  },
  {
    "name": "Centro Armonia Spanish Immersion School",
    "website": "http://armoniapreschool.com/",
    "email": "info@armoniapreschool.com",
    "city": "San Jose",
    "phone": "408-609-3383",
    "state": "California"
  },
  {
    "name": "KIPP Heritage Academy",
    "website": "https://kippnorcal.org/wp-signup.php?new=www.kippbayarea.org",
    "email": "info@kippnorcal.org",
    "city": "San Jose",
    "phone": "408-318-7526",
    "state": "California"
  },
  {
    "name": "ACE Charter Schools",
    "website": "https://www.acecharter.org/",
    "email": "info@acecharter.org",
    "city": "San Jose",
    "phone": "408-295-6008",
    "state": "California"
  },
  {
    "name": "KSS Immersion Preschool",
    "website": "https://ksspreschool.com/",
    "email": "inquiries@ksspreschool.com",
    "city": "San Jose",
    "phone": "877-442-2555",
    "state": "California"
  },
  {
    "name": "Scholars Academy",
    "website": "https://scholarsacademyschool.com/",
    "email": "contact@scholarsacademyschool.com",
    "city": "San Jose",
    "phone": "408-238-2500",
    "state": "California"
  },
  {
    "name": "Russian School",
    "website": "https://www.russianschool.com/locations/evergreen",
    "email": "evergreen@russianschool.com",
    "city": "San Jose",
    "phone": "669-234-7870",
    "state": "California"
  },
  {
    "name": "The Harker School",
    "website": "https://www.harker.org/",
    "email": "communications@harker.org",
    "city": "San Jose",
    "phone": "408-249-2510",
    "state": "California"
  },
  {
    "name": "Merryhill Preschool",
    "website": "https://www.merryhillschool.com/preschools/san-jose/north-capitol-ave/?/utm_medium=directory",
    "email": "swong1@springedugroup.com",
    "city": "San Jose",
    "phone": "408-254-1280",
    "state": "California"
  },
  {
    "name": "San Jose Unified School",
    "website": "https://web.sjusd.org/our-schools/schools/?/schools/elementary-schools/",
    "email": "info@sjusd.org",
    "city": "San Jose",
    "phone": "408-535-6410",
    "state": "California"
  },
  {
    "name": "Safari Kid",
    "website": "https://safarikidusa.com/",
    "email": "info@safarikidca.com",
    "city": "San Jose",
    "phone": "408-600-6031",
    "state": "California"
  },
  {
    "name": "San Jose Montessori School",
    "website": "http://www.sanjosemontessori.org/",
    "email": "sjmsinfo@gmail.com",
    "city": "San Jose",
    "phone": "408-377-9888",
    "state": "California"
  },
  {
    "name": "Empire Montessori Preschool",
    "website": "http://www.empiremontessoripreschool.com/downtown/home?loc=Downtown",
    "email": "empiremontessoripreschool@yahoo.com",
    "city": "San Jose",
    "phone": "408-295-5900",
    "state": "California"
  },
  {
    "name": "Precious Moments Preschool",
    "website": "https://priyaspreciousmoments.net/",
    "email": "priya@priyaspreciousmoments.net",
    "city": "San Jose",
    "phone": "408-931-6218",
    "state": "California"
  },
  {
    "name": "Kidango - Dorsa Center",
    "website": "https://www.kidango.org/",
    "email": "hello@kidango.org",
    "city": "San Jose",
    "phone": "408-429-2689",
    "state": "California"
  },
  {
    "name": "Champion School",
    "website": "http://www.championschool.org/",
    "email": "admin@championschool.org",
    "city": "San Jose",
    "phone": "408-973-8696",
    "state": "California"
  },
  {
    "name": "The Center for Early Education",
    "website": "https://www.centerforearlyeducation.org/",
    "email": "info@cee-school.org",
    "city": "West Hollywood",
    "phone": "323-651-0707",
    "state": "California"
  }
]
